import React , {useEffect} from "react";
import { withRouter } from "react-router-dom";
import { useDispatch , useSelector} from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import { refreshUserDetails,
  setMyTransactions, setBestPricing, setMySavedSearches, setCurrentSearch, setSearchResult, setSurveyformStep,  setPhoneVerificationFrom ,SignOff} from '../../../../reducers/client/actions';
import { PowerEnterCreditBonus, getbestPricing, getMyAccount,  getMyTransactions, doNewDownload,  savePhoneNumber, smsVerification,doLogOff } from '../../../../service/client';
import Swal from 'sweetalert2';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { renderCount } from "../../../../utils";

// Phone number verification step component
 function PhoneVerification(props){

  const onlyCountries = ['au', 'at', 'be', 'ca', 'dk', 'fi', 'fr', 'de', 'ie', 'it', 'jp', 'mx', 'nl', 'nz', 'sg', 'es', 'se', 'ch', 'us', 'in', 'gb', 'no', 'il'];
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState('');
  const [resendloading, setResendLoading] = React.useState('');
  const [downloadLoading, setDownloadLoading] = React.useState('');
  const [OtpValidate, setOtpValidate] = React.useState('');
  let FormStep = useSelector((store) => store.client.NewUserServeyFormStep);
  let activeStep = FormStep.activeStep;
  const PhoneVerificiationForm = useSelector((store) => store.client.PhoneVerificiationForm);
  const [verificationCode, setVerificationCode] = React.useState('');
  const [Phone, setPhone] = React.useState("");
  const currentSearch = useSelector((store) => store.client.currentSearch);
  const [openDialog, setDialogOpen] = React.useState(false);
const [limited, setLimited] = React.useState(false);

  let searchResult = useSelector((store) => store.client.searchResult);
  let Summary = 'JSON_SUMMARY' in searchResult ? JSON.parse(searchResult.JSON_SUMMARY) : {};
  let searchCount = Summary.Num_Matched_Officials;
  let bestPricing = useSelector((store) => store.client.bestPricing);
  let forcePopulationUpdate = Cookies.get('forcePopulationUpdate');
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    setPhone(PhoneVerificiationForm.phone);
   },[PhoneVerificiationForm]);

 

  const handlePhone = (phone) => {
    setPhone(phone);
    setOtpValidate('');
    dispatch(setPhoneVerificationFrom({ phone: phone, action: "updatePhone" }));
  }

  const handleVerification = (code)=>{
    setOtpValidate('');
    setVerificationCode(code);
  }
  
  const handleDialogClose = () => {
    setDialogOpen(false);
  };


  const handleBack= ()=>{
    dispatch(setSurveyformStep({activeStep: 0, skipStep:false}));
  }
// function to resend phone verification code to entered phone number
const handleResendCode = () => {
  setResendLoading('loading');
  setVerificationCode("");
  //let params = PhoneVerificiationForm;
  let params = { phone:PhoneVerificiationForm.phone,action:PhoneVerificiationForm.action,countDT:count};
  savePhoneNumber(params).then(res =>
    handleResendResponse(res),
  )
}
// function to handle resend code 
const handleResendResponse = (response) => {
  setOtpValidate('');
  setResendLoading('');
  if (response.data.code === 200) {
    let phone = PhoneVerificiationForm.phone;
    dispatch(setPhoneVerificationFrom({ phone: phone, otp: response.data.data.smscode, action:"verify"}));
  }else if(response.data.code===422){
    if(count==5)
    {
      setLimited(true);
    }
    setOtpValidate(response.data.message);
    //setLimited(true);
    //console.log('check 1',limited);
  } else {
    setOtpValidate(response.data.data.message);
    //console.log('check 2');
  }

}
  const handleSubmit = ()=>{
    setOtpValidate('');
    if (PhoneVerificiationForm.length===0) {
      setOtpValidate("Please enter phone number ");
      return false;
    }
    if (PhoneVerificiationForm.action === "updatePhone") {
      setLoading('loading');
      let params = { phone:PhoneVerificiationForm.phone,action:PhoneVerificiationForm.action,countDT:count};
      savePhoneNumber(params).then(res =>
        handleResponse(res),
      ).catch(
        function (error) {
          //console.log("error",error)
          setLoading('');
          //setOtpValidate("Please enter valid phone number");
         
        }
      )
    } else if (PhoneVerificiationForm.action === "verify") {
      
      let phonedata = PhoneVerificiationForm;
      /*if (phonedata.otp !== parseInt(verificationCode)) {
        setOtpValidate("Invalid sms verfication code!");
        return false;
      }*/
      setLoading('loading');
      let params = { phone: phonedata.phone, smscode: verificationCode ,countDT:count};
      smsVerification(params).then(res =>
        handleResponse(res),
      )
  }
}

  const handleResponse = (res) => {
    if (res.data.code === 200) {
      if (PhoneVerificiationForm.action === "updatePhone") {
        let phoneNumber = PhoneVerificiationForm.phone;
        let params = { phone: phoneNumber, otp: res.data.data.smscode, action:"verify" }
        dispatch(setPhoneVerificationFrom(params));
        setLoading('');
      }else if(PhoneVerificiationForm.action === "verify") {
        setCount(0);
        setLoading('');
        let phoneNumber = PhoneVerificiationForm.phone;
        let params = { phone: phoneNumber, otp: res.data.data.smscode, action:"verificationSuccess" }
        dispatch(setPhoneVerificationFrom(params));
        getMyAccount().then(res =>
          dispatch(refreshUserDetails(res.data.data))
        );
        if(FormStep.skipStep===true){
          dispatch(setCurrentSearch([]));
          dispatch(setSearchResult([]));
          dispatch(setMySavedSearches([]));
          setTimeout(function () {
            props.toggle()
          }, 2000);
        }else{
          let bestParams = {
            checkout_paid_records: 0,
            checkout_unpaid_records: searchCount,
          }
          getbestPricing(bestParams).then(bpres =>
            dispatch(setBestPricing(bpres.data.data)),
          )
          if(forcePopulationUpdate==="true" && typeof forcePopulationUpdate!=="undefined"){
            handleDownload();
          }
        }
      }
    } else{
      if(res.data.code===422)
      {
          if(count==5)
          {
            setLoading('');
            setOtpValidate(res.data.message);
            setLimited(true);
          } else {
            if(PhoneVerificiationForm.action === "verify") 
            {
                setCount(count + 1);
            }
            setLoading('');
            setOtpValidate(res.data.message);
            //setLimited(true);
            //console.log('check 3',limited);
          }
      } else if(res.data.code===400){
          setLoading('');
          setOtpValidate(res.data.message);
          setLimited(true);
      } else if(res.data.code===211){
        setLoading('');
        setOtpValidate('Number is Invalid.Please enter another number to verify');
      }
      
    }
  }

  const handleDownload = ()=>{
    //if(searchCount<=300){
      if(searchCount<=100){
      setDownloadLoading('loading');
      var element = document.getElementById("body");
      element.classList.add("downloadloading");
      let params = {
        lastSearchNumMatched: parseInt(searchResult.lastSearchNumMatched),
        lastSearchParams: currentSearch.search_params,
        currentTableID: searchResult.currentTableID,
        expired: currentSearch.expired,
        saveddownload_id: currentSearch.id,
        record2Charge: parseInt(searchResult.lastSearchNumMatched),
        download_name: currentSearch.name
      }
     /*if(searchCount>100){
      let remaining = parseInt(searchCount)-100;
      let creditParams = {credits: remaining, notes: "Immediate download reward" };
      PowerEnterCreditBonus(creditParams).then(res =>
        handleToproceedDownload(res, params),
      )
     }else{*/
      setDialogOpen(true);
      doNewDownload(params).then(res =>
        DownloadFile(res),
      )
     //}
      
    }else{
      if(bestPricing.option === "Purchase"){
        props.history.push('/search/download/purchase-credits');
      }else if(bestPricing.option === "Upgrade"){
        props.history.push("/search/download/upgrade-plan");
      }
    }
  }

  /*const handleToproceedDownload=(res, params)=>{
    if(res.data.code===200){
      setDialogOpen(true);
      doNewDownload(params).then(res =>
        DownloadFile(res),
      )
    }
  }*/
 
   // download record file and redirect my saved search page
   const DownloadFile = (response) => {
    setDownloadLoading('');
    var element = document.getElementById("body");
    element.classList.remove("downloadloading");
    if (response.data.code === 200) {
      setDialogOpen(false);
      let data = response.data.data;
      let file = data.file;
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = file;
      a.download = data.file_name;
      document.body.appendChild(a);
      a.click();

      /*let creditParams = {credits: 100, notes: "Account activation - Free Credits" };
      PowerEnterCreditBonus(creditParams).then(res => {
        //console.log("Power Entry - Free Credits"),
      })*/
      getMyAccount().then(res =>
        dispatch(refreshUserDetails(res.data.data))
      );
      getMyTransactions().then(res =>
        dispatch(setMyTransactions(res.data.data)),
      )
      setTimeout(function () {
        props.history.push('/my-saved-searches#downloads')
      }, 2000);
      Cookies.remove('forcePopulationUpdate');
      setTimeout(function () {
        props.toggle()
      }, 2000);
      //window.location='/my-saved-searches#downloads';

    } else {
      setDialogOpen(false);
      Swal.fire({
        text:"Your Download is Not Successful. Please Try Again",
          showCancelButton: true,
          showCloseButton: true,
          showConfirmButton:false,
          cancelButtonText: 'Back',
          icon: ''
        });
    }
  }

  const handleSignOff = ()=>{
    doLogOff().then(res =>
      dispatch(SignOff({}))
    )
  }
//console.log("count====", count)
  
  return (
    <>
          <div className="row">
            <div className="col ">
            {
              PhoneVerificiationForm.action !== 'verificationSuccess' ?
                <PhoneInput
                inputclassName="w-100 123"
                containerclassName="mt-3 w-100"
                defaultErrorMessage="Invalid Number"
                country={'us'}
                autoFormat={true}
                value={Phone}
                onlyCountries={onlyCountries}
                onChange={(phone) => handlePhone("+" + phone)}
                preferredCountries={['gb', 'us']}
                />
              :
              <></>
            }
                { PhoneVerificiationForm.action === 'verify' && resendloading==="" ? 
                    <div className="col-12 mt-3">
                      <div className="alert mt-3 alert-success prev_result_msg resendmsg">
                        Please enter the 6 digit verification code we have texted to your mobile
                    </div>
                      <input type="text" value={verificationCode} onChange={(e)=>handleVerification(e.target.value)} placeholder="VERIFICATION NUMBER" className=" mt-3 form-control text-center" name="verification_code" />
                    </div>
                    :
                    <></>
                }
              
               {
                  OtpValidate !== '' ?
                    <div className="alert mt-3 alert-danger">
                    {OtpValidate}
                    </div>
                  :
                  <></>
                }
                {
                  limited  ?
                  <div className="col-12 mt-3  text-center">
                  <Button variant="contained" className={" ml-2 "} color="primary" onClick={()=>handleSignOff()}> Sign Off</Button>
                
                </div>
                  :
                  <></>
                }
                
            </div>
          </div>
          <br/>
          {
            PhoneVerificiationForm.action === 'verificationSuccess' ?
              <div className="row">
                  
                <div className="col-12  text-center">
                  <div className="alert mt-3 alert-success prev_result_msg resendmsg">
                    Success! Your mobile number has been validated.
                  </div>
                </div>
                {/* { searchCount<=300 &&  FormStep.skipStep===false?
                   <div className="col-12  text-center">
                    <div className="alert mt-3 alert-success prev_result_msg resendmsg">
                    <p>  DOWNLOAD NOW to get all {renderCount(searchCount)} contact(s) for FREE. </p>
                    <p>If you choose to DOWNLOAD LATER, you will have only 100 FREE credits. </p>
                    </div>
                  </div>
                  :
                  <></>

                } */}
               { searchCount<=300 &&  FormStep.skipStep===false?
                <div className="col-12  text-center">
                  <Button variant="contained" className={" ml-2  " + downloadLoading} color="primary" onClick={()=>handleDownload()}> Download Contacts</Button>
                  {/* <Button variant="contained" className={" ml-2 btn btn-secondary btn-pa  "} color="primary" onClick={()=>handleDownloadLater()}> Download Later</Button> */}
                </div>
                :
                <></>
               }
              </div>
              :
              <div className="row">
                <div className="col-12  text-center">
                  {
                    FormStep.skipStep===false && limited===false?
                      <Button   color="inherit" className={" ml-2 btn btn-secondary btn-pa  " }   disabled=   {activeStep === 0}  onClick={()=>handleBack()} sx={{ mr: 1 }} >
                          Back
                      </Button>
                    :
                    <></>
                  }
                  {limited===false?
                  <>
                    {count<=4?
                    <Button className={"ml-2 " + loading} variant="contained" color="primary" onClick={()=>handleSubmit()}>
                    { PhoneVerificiationForm.action === 'verify' && resendloading==="" ? "Verify" : "GET CODE" }
                    </Button>
                    : <></>
                    }
                    <Button variant="contained" className={" ml-2 btn btn-secondary btn-pa  "+resendloading } color="primary" onClick={handleResendCode}> Resend Code</Button>
                    </>
                    :
                    <></>
                  }
                </div>
            </div>
          }
           <Dialog
            open={openDialog}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Downloading</DialogTitle>
            <DialogContent>
              <DialogContentText className="text-center" id="alert-dialog-description">
                Please be patient, we are putting together your file now, it may take 30 to 45 seconds.
                <p><img src="/images/loading.gif" /> </p>
              </DialogContentText>
            </DialogContent>

          </Dialog>
    </>
  );

}


export default withRouter(PhoneVerification);