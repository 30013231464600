// Vendor Imports
import React from "react";
import MaterialTable from "@material-table/core";
import $ from "jquery";
import { CsvBuilder } from 'filefy';
import moment from 'moment';
import { Link } from "react-router-dom";

// Local Imports
import { getActiveAccStats } from "../../../service/admin";

// Csv export function
const exportCsv = (allColumns, allData) => {
    const columns = allColumns
        .filter(columnDef => {
            return !columnDef.hidden && columnDef.field && columnDef.export !== false;
        });

    const data = allData.map(rowData =>
        columns.map(columnDef => rowData[columnDef.field])
    );

    // eslint-disable-next-line no-unused-vars
    const builder = new CsvBuilder('PA_Admin_Dashboard_' + moment().format("YYYY-MM-DD") + '.csv')
        .setDelimeter(",")
        .setColumns(columns.map(columnDef => columnDef.title))
        .addRows(data)
        .exportFile();
}
let processing = false;

// Active account stats table listing
class ActiveAccStatsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            temp: null,
        };
        this.updateTabledata = this.updateTabledata.bind();
    }

    componentDidMount() {
        // Api call to fetch table details
        getActiveAccStats().then(res => this.handleResponse(res))
    }

    // Handling api response
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data.values,
                temp: res.data.data.values
            });
        }
    };

    // Filter function for power users
    updateTabledata = () => {
        let levels = [];
        if ($('#power_custom').is(":checked")) {
            levels.push("Power Custom");
        }
        if ($('#power_entry').is(":checked")) {
            levels.push("Power Entry");
        }
        if ($('#power_15').is(":checked")) {
            levels.push("Power 15");
        }
        if ($('#power_75').is(":checked")) {
            levels.push("Power 75");
        }
        if ($('#power_max').is(":checked")) {
            levels.push("Power Max");
        }
        if (levels.length === 0 || levels.length === 5) {
            this.setState({ tableDetails: this.state.temp });
            return true;
        } else {
            let val = []
            this.state.temp.forEach(t => {
                if (levels.includes(t.Sub_level)) {
                    val.push(t)
                }
            });
            this.setState({ tableDetails: val })
            return true;
        }
    };

    // Filter function for power users
    filterDataTable = () => {
        if (processing) {
            clearTimeout(processing);
        }
        //processing = setTimeout(function () {
        this.updateTabledata();
        // }.bind(this), 2000);
    }

    render() {
        // Table columns
        const columns = [
            { title: "View Account", render: rowData => <Link className="edit-btn" to={`/admin/edit-user-details/primary_user/${rowData.id}`}>View</Link> },
            {
                title: "ID",
                field: "id"
            },
            { title: "Sub Level", field: "Sub_level" },
            { title: "Company Name", field: "Company_name" },
            { title: "Primary User Name", field: "Primary_user_name" },
            { title: "Primary User Email", field: "Primary_user_email" },
            {
                title: "Subscription EXPIRATION", field: "Subscription_expiration",
                cellStyle: (e, rowData) => {
                    return { backgroundColor: rowData.Subscription_expiration_color };
                }
            },
            {
                title: "Sub Users", field: "Sub_user",
                cellStyle: (e, rowData) => {
                    return { backgroundColor: rowData.Sub_user_color };
                }
            },
            {
                title: "Credit Balance", field: "Credit_balance",
                cellStyle: (e, rowData) => {
                    return { backgroundColor: rowData.Credit_balance_color };
                }
            },
            {
                title: "Weekly Alert OFF", field: "weekly_alert_off",
                cellStyle: (e, rowData) => {
                    return { backgroundColor: rowData.weekly_alert_off_color };
                }
            },
            {
                title: "Download Files", field: "download_files",
                cellStyle: (e, rowData) => {
                    return { backgroundColor: rowData.download_files_color };
                }
            },
            {
                title: "Primary User - Last Download", field: "primary_last_download",
                cellStyle: (e, rowData) => {
                    return { backgroundColor: rowData.primary_last_download_color };
                }
            },
            {
                title: "Primary User - Updated Records Available", field: "primary_update_records",
                cellStyle: (e, rowData) => {
                    return { backgroundColor: rowData.primary_update_records_color };
                }
            },
            {
                title: "Primary User - Last Login", field: "primary_last_login",
                cellStyle: (e, rowData) => {
                    return { backgroundColor: rowData.primary_last_login_color };
                }
            },
            {
                title: "Sub Users - Last Download", field: "subuser_last_download",
                cellStyle: (e, rowData) => {
                    return { backgroundColor: rowData.subuser_last_download_color };
                }
            },
            {
                title: "Sub Users - Updated Records Available", field: "subuser_update_records",
                cellStyle: (e, rowData) => {
                    return { backgroundColor: rowData.subuser_update_records_color };
                }
            },
            {
                title: "Sub Users- Last Login", field: "subuser_last_login",
                cellStyle: (e, rowData) => {
                    return { backgroundColor: rowData.subuser_last_login_color };
                }
            }
        ];

        return (
            <section className="admin-table" >
                {this.state.tableDetails ?
                    <div>
                        <div className="row ml-1">
                            <div className="col-md-10 mt-10">
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" id="power_custom" onClick={this.filterDataTable} />Power Custom </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" id="power_entry" onClick={this.filterDataTable} />Power Entry </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" id="power_15" onClick={this.filterDataTable} />Power 15 </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" id="power_75" onClick={this.filterDataTable} />Power 75 </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" id="power_max" onClick={this.filterDataTable} />Power Max </label>
                                </div>
                            </div>
                        </div>

                        <MaterialTable
                            className="table display search-table table-responsive"
                            data={this.state.tableDetails}
                            columns={columns}
                            title=""
                            options={{
                                sorting: true,
                                actionsColumnIndex: -1,
                                paging: true,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 50],
                                emptyRowsWhenPaging: false,
                                search: true,
                                toolbar: true
                            }}
                            actions={[
                                {
                                    icon: () => (
                                        <button type="button" className="btn btn-primary edit-btn down-btn">Download</button>
                                    ),
                                    isFreeAction: true,
                                    onClick: () => exportCsv(columns, this.state.tableDetails),
                                },
                            ]}
                            localization={{
                                toolbar: {
                                    exportTitle: ''
                                }
                            }}
                        />
                    </div>
                    : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >

                            <span className="sr-only">Loading...</span>
                        </div></div>
                }

            </section >

        );
    }
}

// Export
export default ActiveAccStatsTable;
