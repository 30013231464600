import React, {useState, useEffect} from "react";
import {Helmet} from 'react-helmet';
import MaterialTable from '@material-table/core';
import { Link } from "react-router-dom";
import {DownloadLocGovAccountFile, LocalGovAccountInfo} from '../../../service/client';


function LocalGovAccountFile(){
 
  let [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let columns=[
    {title:"Name", field:"name", render: () => renderDownloadLink()},
    {title:"Last Updated", field:"last_updated_date", render: (rowData) => renderLastUpdated(rowData)},
    {title:"Last Downloaded", field:"downloaded_date"}
];
useEffect(()=>{
  if (window.analytics) {
    window.analytics.page();
  }
  getLocalGovAccountInfo();
},[])

//function to call download api
const getLocalGovAccountInfo = () => {
  LocalGovAccountInfo().then(res =>
    setData([res.data.data]),
    setIsLoading(false)
  )  
}



 //function to call download api
 const handleDownload = () => {
  var element = document.getElementById("body");
  element.classList.add("downloadloading");
  DownloadLocGovAccountFile().then(res =>
    DownloadFile(res),
  )  
 
}

// function to download csv file
const DownloadFile = (response) => {
  var element = document.getElementById("body");
  element.classList.remove("downloadloading");
  if (response.data.code===200) {
    let data = response.data.data;
    let file = data.file;
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = file;
    a.download = data.file_name;
    document.body.appendChild(a);
    a.click();
  } else {
    alert("Your Download is Not Successful. Please Try Again");
  }
}
const renderDownloadLink = () => {
  return(
  <Link onClick={(e) => handleDownload()}>
    Local Government Account File <i style={{ color: "#076599" }} className="ml-2 fa fa-download" aria-hidden="true"></i>
  </Link>
  )
};

const renderLastUpdated = (rowData) => {
  if(rowData.IsUpdated){
    return( <>{rowData.last_updated_date}  <sup><span class='new-text-small'>New </span></sup></>)
  }else{
    return(<> {rowData.last_updated_date}</> )
  }
};


  return(
      <>
      <Helmet>
        <title> Local Government Account File - Power Almanac </title>
      </Helmet>
      <section className="my-search ">
        <div className="container">
          <div className="row">
            <div className="col">
              <ul className="breadcrumb">
                <li>Local Government Account File</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-10 my-table-container">
        
        <div className="row">
          <div className="col">
            <div className=" mt-3 search-list-table">
             
              <MaterialTable
                className="table table-responsive table-striped display search-table table-responsive"
                id="resultsTable"
                columns={columns}
                data={data}
                isLoading={isLoading}
                title="Local Government Account File"
                options={{
                  headerStyle: {
                    whiteSpace: 'nowrap'
                  },
                  actionsColumnIndex: -1,
                  tableLayout: "auto", 
                  sorting: true, 
                  paging: false, 
                  pageSize: 10, 
                  search:false,
                  emptyRowsWhenPaging: false
                }}
              />
            </div>
          </div>
        </div>

      </section>
      <div className="downloadmodal">
          <div className="center">
            <div className="content">
              <p id="processmessage" > Please be patient, we are putting together your file now, it may take 30 to 45 seconds. </p>
              <img alt="poweralmanac" src="/images/loading.gif"/> 
              </div>
            </div>
          </div>
    </>
  )
}
export default LocalGovAccountFile;