// Vendor Imports
import React from "react";

// Local Imports
import { getUserTransInformation } from "../../../../service/admin";

class TransactionInformationComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
        };
    }

    componentDidMount() {
        // Api call to get transaction information
        getUserTransInformation(this.props.userId).then(res => this.handleResponse(res))
    }

    // Handle response
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data,
            });
        }
    };
    //to download the S3 object
    downloadFile = (dataFile,dataName) => {
        let file = dataFile;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = file; 
        a.download = dataName;
        document.body.appendChild(a);
        a.click();
    }



    render() {
        return (
            <section className="admin-table">
                {this.state.tableDetails ?
                    <div className="table-responsive">
                        <table className="table table-bordered ">
                            <thead>
                                <tr>
                                    <th scope="col" width="40%">Main Download File</th>
                                    <th scope="col" width="15%">Downloaded</th>
                                    <th scope="col" width="15%"># Records</th>
                                    <th scope="col" width="15%">Deleted</th>
                                    <th scope="col" width="15%">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.userType === "primary_user" ?
                                    <>
                                        <tr>
                                            <td colSpan="5">Master Account</td>
                                        </tr>
                                        {this.state.tableDetails.Master_Account.map((listValue, index) => {
                                            return (
                                                <>
                                                    <tr key={index}>
                                                        <td>{listValue['Main_Download File']}</td>
                                                        <td>{listValue.Downloaded}</td>
                                                        <td>{listValue['#Records']}</td>
                                                        <td>{listValue.Deleted}</td>
                                                        <td><a className="edit-btn inline-btn" data-toggle="collapse" href={"#MasterInfo" + index} role="button" aria-expanded="false" aria-controls={"MasterInfo" + index}>show / close</a></td>
                                                    </tr>
                                                    <tr className="collapse" id={"MasterInfo" + index}>
                                                        <td colSpan="5">
                                                            <table style={{ width: '100%' }}>
                                                                <tbody>
                                                                    {listValue.Download_Details.map((downDet, i) => {
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td scope="col" width="40%">{downDet.File_Name}</td>
                                                                                <td scope="col" width="15%">{downDet.Downloaded_Date}</td>
                                                                                <td scope="col" width="15%"></td>
                                                                                <td scope="col" width="15%"></td>
                                                                                <td scope="col" width="15%"><a style={{cursor: "pointer"}} onClick={() => this.downloadFile(downDet.Download_File,downDet.File_Name)} >Download</a></td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </>
                                            );
                                        })}</> : <></>}
                                <tr>
                                    <td colSpan="5">Subuser Account</td>
                                </tr>
                                {this.state.tableDetails.Subuser_Account.map((listValue, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <td>{listValue['Main_Download File']}</td>
                                                <td>{listValue.Downloaded}</td>
                                                <td>{listValue['#Records']}</td>
                                                <td>{listValue.Deleted}</td>
                                                <td><a className="edit-btn inline-btn" data-toggle="collapse" href={"#SubUserInfo" + index} role="button" aria-expanded="false" aria-controls={"SubUserInfo" + index}>show / close</a></td>
                                            </tr>
                                            <tr className="collapse" id={"SubUserInfo" + index}>
                                                <td colSpan="5">
                                                    <table style={{ width: '100%' }}>
                                                        <tbody>
                                                            {listValue.Download_Details.map((downDet, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td scope="col" width="40%">{downDet.File_Name}</td>
                                                                        <td scope="col" width="15%">{downDet.Downloaded_Date}</td>
                                                                        <td scope="col" width="15%"></td>
                                                                        <td scope="col" width="15%"></td>
                                                                        <td scope="col" width="15%"><a style={{cursor: "pointer"}} onClick={() => this.downloadFile(downDet.Download_File,downDet.File_Name)}>Download</a></td>
                                                                    </tr>
                                                                );
                                                            })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                })}
                                {this.props.userType === "primary_user" ?
                                    <tr>
                                        <td colSpan="2">Master Account ({this.state.tableDetails.user_email})</td>
                                        <td colSpan="3"><strong>Total of Paid Records</strong><br /><strong>{this.state.tableDetails.paid_records}</strong></td>
                                    </tr> : <></>}
                            </tbody>
                        </table>
                    </div>
                    : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >

                            <span className="sr-only">Loading...</span>
                        </div></div>
                }
            </section>

        );
    }

}

// Export
export default TransactionInformationComponent;
